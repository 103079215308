import { Box, Typography } from '@mui/material';
import React from 'react';

export default function QuickLinksPage() {
    return (
        <Box>
            <Box
                p={4}
                sx={{
                    backgroundColor: '#f6f8f9',
                    flexDirection: 'column',
                    height: '250px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'left',
                    paddingLeft: '80px',
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: '28px',
                            color: '#5cb5b3',
                            fontFamily: 'Open Sans',
                            fontWeight: 400,
                            letterSpacing: '-0.5px',
                        }}
                    >
                        Quick Links
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: '#787878',
                            fontFamily: 'Open Sans',
                        }}
                    >
                        Useful Links that we use everyday
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ padding: '80px' }}>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#5cb5b3',
                        fontWeight: 400,
                        fontSize: '18px',
                        fontFamily: 'Open Sans',
                        letterSpacing: '-0.5px',
                        paddingBottom: '20px',
                    }}
                >
                    Links
                </Typography>
                <ul>
                    <li
                        style={{
                            color: '#787878',
                            fontFamily: 'Open Sans',
                            fontSize: 14,
                        }}
                    >
                        {' '}
                        <a
                            style={{ color: '#787878', textDecoration: 'none' }}
                            href='https://www.7-day.com/'
                            target='_blank'
                        >
                            7DAY Website
                        </a>
                    </li>
                    <li
                        style={{
                            color: '#787878',
                            fontFamily: 'Open Sans',
                            fontSize: 14,
                        }}
                    >
                        {' '}
                        <a
                            style={{ color: '#787878', textDecoration: 'none' }}
                            href='https://7day.atlassian.net/'
                            target='_blank'
                        >
                            Atlassian Apps (Bitbucket, Jira etc)
                        </a>
                    </li>
                    <li
                        style={{
                            color: '#787878',
                            fontFamily: 'Open Sans',
                            fontSize: 14,
                        }}
                    >
                        {' '}
                        <a
                            style={{ color: '#787878', textDecoration: 'none' }}
                            href='https://www.linkedin.com/company/7-day-au'
                            target='_blank'
                        >
                            LinkedIn Company Page
                        </a>
                    </li>
                    <li
                        style={{
                            color: '#787878',
                            fontFamily: 'Open Sans',
                            fontSize: 14,
                        }}
                    >
                        {' '}
                        <a
                            style={{ color: '#787878', textDecoration: 'none' }}
                            href='https://slack.com/'
                            target='_blank'
                        >
                            Slack
                        </a>
                    </li>
                    <li
                        style={{
                            color: '#787878',
                            fontFamily: 'Open Sans',
                            fontSize: 14,
                        }}
                    >
                        {' '}
                        <a
                            style={{ color: '#787878', textDecoration: 'none' }}
                            href='https://www.gitkraken.com/'
                            target='_blank'
                        >
                            GitKraken
                        </a>
                    </li>
                    <li
                        style={{
                            color: '#787878',
                            fontFamily: 'Open Sans',
                            fontSize: 14,
                        }}
                    >
                        {' '}
                        <a
                            style={{ color: '#787878', textDecoration: 'none' }}
                            href='https://www.postman.com/'
                            target='_blank'
                        >
                            Postman
                        </a>
                    </li>
                    <li
                        style={{
                            color: '#787878',
                            fontFamily: 'Open Sans',
                            fontSize: 14,
                        }}
                    >
                        {' '}
                        <a
                            style={{ color: '#787878', textDecoration: 'none' }}
                            href='https://www.dropbox.com/'
                            target='_blank'
                        >
                            Dropbox
                        </a>
                    </li>
                    <li
                        style={{
                            color: '#787878',
                            fontFamily: 'Open Sans',
                            fontSize: 14,
                        }}
                    >
                        {' '}
                        <a
                            style={{ color: '#787878', textDecoration: 'none' }}
                            href='https://www.lastpass.com/'
                            target='_blank'
                        >
                            LastPass
                        </a>
                    </li>
                    <li
                        style={{
                            color: '#787878',
                            fontFamily: 'Open Sans',
                            fontSize: 14,
                        }}
                    >
                        {' '}
                        <a
                            style={{ color: '#787878', textDecoration: 'none' }}
                            href='https://www.figma.com/'
                            target='_blank'
                        >
                            Figma
                        </a>
                    </li>
                </ul>
            </Box>
        </Box>
    );
}
