import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';
import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import Person2Icon from '@mui/icons-material/Person2';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PlaceIcon from '@mui/icons-material/Place';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import DownloadIcon from '@mui/icons-material/Download';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LockIcon from '@mui/icons-material/Lock';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import SailingIcon from '@mui/icons-material/Sailing';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import QrCode2Icon from '@mui/icons-material/QrCode2';

export default function IconsPage() {
    return (
        <Box>
            <Box
                p={4}
                sx={{
                    backgroundColor: '#f6f8f9',
                    flexDirection: 'column',
                    height: '250px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'left',
                    paddingLeft: '80px',
                }}
            >
                <Box>
                    <Typography
                        variant='h6'
                        noWrap
                        component='a'
                        sx={{
                            fontSize: 28,
                            color: '#5cb5b3',
                            fontFamily: 'Open Sans',
                            fontWeight: 400,
                            letterSpacing: '-0.5px',
                        }}
                    >
                        Icons
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: '#787878',
                            fontFamily: 'Open Sans',
                        }}
                    >
                        Guidelines and best practices you should follow when
                        designing custom icons for 7DAY applications.
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ padding: '80px' }}>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#5cb5b3',
                        fontWeight: 400,
                        fontSize: '18px',
                        fontFamily: 'Open Sans',
                        letterSpacing: '-0.5px',
                    }}
                >
                    Icons
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#787878',
                        fontFamily: 'Open Sans',
                        fontSize: 14,
                    }}
                >
                    {' '}
                    7DAY icons adopt a system to ensure a cleaner, more
                    consistent and more unified look to all LEAP applications.
                    This section will describe the guidelines and best practices
                    you should follow when designing custom icons for 7DAY
                    applications.
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#787878',
                        fontFamily: 'Open Sans',
                        fontSize: 14,
                    }}
                >
                    Always use the layout grid as a guideline which placing
                    artwork elements. Centre your illustration within the 20px x
                    20px drawing space and allow a padding of approximately 2px
                    from each side.
                </Typography>

                <Box sx={{ paddingTop: '50px' }}>
                    <TableContainer>
                        <Table sx={{ width: 600 }} aria-label='simple table'>
                            <TableBody>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <AddCircleIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import AddCircleIcon from
                                            '@mui/icons-material/AddCircle';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <AddIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import AddIcon from
                                            '@mui/icons-material/Add';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <EditIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import EditIcon from
                                            '@mui/icons-material/Edit';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <DeleteIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import DeleteIcon from
                                            '@mui/icons-material/Delete';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <CalendarMonthIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import CalendarMonthIcon from
                                            '@mui/icons-material/CalendarMonth';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <MoreVertIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import MoreVertIcon from
                                            '@mui/icons-material/MoreVert';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <Person2Icon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import Person2Icon from
                                            '@mui/icons-material/Person2';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <LoginIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import LoginIcon from
                                            '@mui/icons-material/Login';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <LogoutIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import LogoutIcon from
                                            '@mui/icons-material/Logout';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <EmailIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import EmailIcon from
                                            '@mui/icons-material/Email';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <LocalPhoneIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import LocalPhoneIcon from
                                            '@mui/icons-material/LocalPhone';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <PlaceIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import PlaceIcon from
                                            '@mui/icons-material/Place';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <SettingsIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import SettingsIcon from
                                            '@mui/icons-material/Settings';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <NotificationsNoneIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import NotificationsNoneIcon from
                                            '@mui/icons-material/NotificationsNone';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <GroupAddIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import GroupAddIcon from
                                            '@mui/icons-material/GroupAdd';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <DriveFolderUploadIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import DriveFolderUploadIcon from
                                            '@mui/icons-material/DriveFolderUpload';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <DownloadIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import DownloadIcon from
                                            '@mui/icons-material/Download';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <ErrorIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import ErrorIcon from
                                            '@mui/icons-material/Error';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <CheckCircleOutlineIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import CheckCircleOutlineIcon from
                                            '@mui/icons-material/CheckCircleOutline';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <LockIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import LockIcon from
                                            '@mui/icons-material/Lock';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <ArrowCircleDownIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import ArrowCircleDownIcon from
                                            '@mui/icons-material/ArrowCircleDown';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <ArrowCircleUpIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import ArrowCircleUpIcon from
                                            '@mui/icons-material/ArrowCircleUp';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <HelpOutlineIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import HelpOutlineIcon from
                                            '@mui/icons-material/HelpOutline';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <SearchIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import SearchIcon from
                                            '@mui/icons-material/Search';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <VisibilityIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import VisibilityIcon from
                                            '@mui/icons-material/Visibility';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <VisibilityOffIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import VisibilityOffIcon from
                                            '@mui/icons-material/VisibilityOff';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <BusinessIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import BusinessIcon from
                                            '@mui/icons-material/Business';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <HomeIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import HomeIcon from
                                            '@mui/icons-material/Home';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <SailingIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import SailingIcon from
                                            '@mui/icons-material/Sailing';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <DirectionsBoatIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import DirectionsBoatIcon from
                                            '@mui/icons-material/DirectionsBoat';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <DirectionsCarIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import DirectionsCarIcon from
                                            '@mui/icons-material/DirectionsCar';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <TwoWheelerIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import TwoWheelerIcon from
                                            '@mui/icons-material/TwoWheeler';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <DirectionsCarIcon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import DirectionsCarIcon from
                                            '@mui/icons-material/DirectionsCar';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        <QrCode2Icon />
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='left'
                                    >
                                        <pre
                                            style={{
                                                backgroundColor: '#EEEEEE',
                                                textAlign: 'left',
                                                padding: '15px',
                                            }}
                                        >
                                            import QrCode2Icon from
                                            '@mui/icons-material/QrCode2';
                                        </pre>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    );
}
