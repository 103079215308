import {
  Box,
  Card,
  Grid,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

export default function HomePage() {
  const isMobile = useMediaQuery("(max-width: 770px)");
  const isIpad = useMediaQuery("(max-width: 1200px)");
  const navigate = useNavigate();
  const location = useLocation();
  const onClick = (event) => {
    // console.log("register onlick function", `/${event}`);
    // navigate(`/${event}`, { replace: true });

    if (event === "7DAY") {
      window.open((window.location.href = "www.7-day.com"), "_blank");
    }
  };

  const homeLinks = [
    {
      linkName: "7DAY Website",
      link: "www.7-day.com",
      info: "Access our company website",
      photo: '/externalLinks/website.png'
    },
    {
        linkName: "7DAY App - TEST",
        link: "https://app.sdw-test.com/login",
        info: "Access our App - TEST SERVER",
        photo: '/externalLinks/signin.png'
      },
    {
      linkName: "7DAY App - LIVE",
      link: "https://app.7-day.com/login",
      info: "Access our App - LIVE SERVER",
      photo: '/externalLinks/signin.png'
    },
    {
        linkName: "Swagger",
        link: "https://api.sdw-test.com/api",
        info: "Access 7DAY APIs",
        photo: '/externalLinks/swagger.png'
      },
    {
      linkName: "BitBucket/JIRA",
      link: "www.7-day.com",
      info: "Open Atlassian to access BitBucket, Jira and More!",
      photo: '/externalLinks/atlassian.png'
    },
    {
      linkName: "AWS",
      link: "www.7-day.com",
      info: "Open AWS for all your DevOp needs!",
      photo: '/externalLinks/aws.jpg'
    },
    {
        linkName: "Knock",
        link: "https://dashboard.knock.app/login?next=%2Fdorna-test%2Fdevelopment%2Fworkflows",
        info: "Access Knock for 7DAY Notification Intergration",
        photo: '/externalLinks/knock.png'
      },
      {
        linkName: "MailHog",
        link: "https://api.sdw-test.com:8025/",
        info: "Access MailHog to test 7DAY Email Notification Intergration",
        photo: '/externalLinks/mailhog.png'
      },
      {
        linkName: "QR Code Generator",
        link: "https://login.qr-code-generator.com/",
        info: "Access QR Code Generator for 7DAY App Store QWR Code",
        photo: '/externalLinks/qrcodegen.png'
      },
      {
        linkName: "Material UI",
        link: "https://mui.com/",
        info: "Access Material UI Documentation",
        photo: '/externalLinks/matui.png'
      },
      {
        linkName: "Slack",
        link: "https://slack.com/intl/en-au/workspace-signin",
        info: "Access Slack for internal commuication",
        photo: '/externalLinks/slack.png'
      },
      {
        linkName: "Dropbox",
        link: "https://www.dropbox.com/h",
        info: "Access Dropbox for internal documentation",
        photo: '/externalLinks/dropbox.jpg'
      },
      {
        linkName: "GitKraken",
        link: "https://www.gitkraken.com/lp/github-integration-v1?utm_term=&utm_campaign=GitHub+-+PMax+-+Top+ARR&utm_source=adwords&utm_medium=ppc&hsa_acc=1130375851&hsa_cam=21005894355&hsa_grp=&hsa_ad=&hsa_src=x&hsa_tgt=&hsa_kw=&hsa_mt=&hsa_net=adwords&hsa_ver=3&gad_source=1&gclid=Cj0KCQiAw6yuBhDrARIsACf94RVNKJUM3EemLlB3da6oyRYn_Owr68SWq5WFnxqPw5SJu3rmhr5cSkYaAscdEALw_wcB",
        info: "Access GitKraken to connect to the 7DAY BitBucket repo to track changes, pull and push code",
        photo: '/externalLinks/gitkraken.png'
      },
      {
        linkName: "Postman",
        link: "https://www.postman.com/",
        info: "Access Postman to test 7DAY Web API's",
        photo: '/externalLinks/postman.png'
      },
      {
        linkName: "LastPass",
        link: "https://www.lastpass.com/",
        info: "Access LastPass to view, share and manage passwords securely",
        photo: '/externalLinks/lastpass.png'
      },


  ];

  return (
    <>
      <Box>
        <Box
          p={4}
          sx={{
            backgroundColor: "#f6f8f9",
            flexDirection: "column",
            height: "250px",
            display: "flex",
            justifyContent: "center",
            alignItems: "left",
            paddingLeft: "80px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "28px",
                color: "#5cb5b3",
                fontFamily: "Open Sans",
                fontWeight: 400,
                letterSpacing: "-0.5px",
              }}
            >
              Home
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: 14,
                color: "#787878",
                fontFamily: "Open Sans",
              }}
            >
              7DAY's Eco-system has been created to help you access external
              systems and tools at the click of a button and to provide you with
              one central location for brand guidlines to achieve a more
              consistent user experience across 7DAY products and services.
              Explore our libraries and find all of the resources you need to
              get started.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ padding: "80px" }}>
          <Typography
            sx={{
              paddingTop: "20px",
              color: "#5cb5b3",
              fontWeight: 200,
              fontSize: "18px",
              fontFamily: "Open Sans",
              letterSpacing: "-0.5px",
            }}
          >
            External Resources & Tools
          </Typography>
          <Typography
            sx={{
              paddingTop: "20px",
              color: "#787878",
              fontFamily: "Open Sans",
              fontSize: 14,
            }}
          >
            We use a variety of third party applications and tools to build
            7DAY. Everything you need is here in one place.
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              paddingTop: "30px",
              height: "100%",
              width: "100%",
            }}
          >
            <Grid container spacing={4} columns={1}>
              {" "}
              {homeLinks.map((link) => (
                <Grid
                  key={link.linkName}
                  sx={{
                    width: isMobile || isIpad ? "auto" : "20%",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                  item
                >
                  <Card
                    key={link.linkName}
                    //   onClick={() => handleOpen(experience)}
                    sx={{
                      width: "auto",
                      minWidth: "190px",
                      maxWidth: isIpad ? "446px" : "406px",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      boxShadow: 0,
                      background: "none",
                      backgroundColor: "transparent",
                      color: "none",
                      "&:hover": {
                        cursor: "pointer",
                        color: "#fff",
                        backgroundColor: "transparent",
                        background: "none",
                      },
                      "&:focus": {
                        cursor: "pointer",
                        color: "#fff",
                        backgroundColor: "transparent",
                        background: "none",
                      },
                    }}
                  >
                    <CardActionArea
                      // disableRipple
                      // disableTouchRipple
                      sx={{
                        background: "none",
                        backgroundColor: "transparent",
                        color: "#fff",
                        "&:hover": {
                          cursor: "pointer",
                          color: "#fff",
                          backgroundColor: "transparent",
                          background: "none",
                        },
                        "&:focus": {
                          cursor: "pointer",
                          color: "#fff",
                          backgroundColor: "transparent",
                          background: "none",
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        height={
                          window.innerWidth === 1920 ||
                          window.innerWidth === 2496
                            ? "270"
                            : window.innerWidth === 2269
                            ? "100"
                            : "300"
                        }
                        width="400"
                        image={link.photo}
                        alt={link.linkName}
                        sx={{ borderRadius: "8px" }}
                      />

                      <CardContent>
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            // backgroundColor: 'yellow',
                          }}
                        >
                          <Grid
                            sx={{
                              width: "95%",
                              // backgroundColor: 'blue',
                            }}
                          >
                            {" "}
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              sx={{
                                fontFamily: "Open Sans",
                                color: "#787878",
                                fontSize:
                                  window?.screen?.width < 1024
                                    ? "12px"
                                    : window?.screen?.width > 2000
                                    ? "22px"
                                    : "18px",
                                "&:hover": {
                                  color: "#5cb5b3",
                                },
                              }}
                            >
                              {link.linkName}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: "Open Sans",
                            color: "#787878",
                          }}
                        >
                          {link.info}
                        </Typography>
                        
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  {/* <Card
            //   onClick={() => onClick("7DAY")}
         
            sx={{
                width: "auto",
                minWidth: "190px",
                maxWidth: isIpad ? "446px" : "406px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                boxShadow: 0,
                background: "none",
                backgroundColor: "transparent",
                color: "none",
                "&:hover": {
                  cursor: "pointer",
                  color: "#fff",
                  backgroundColor: "transparent",
                  background: "none",
                },
                "&:focus": {
                  cursor: "pointer",
                  color: "#fff",
                  backgroundColor: "transparent",
                  background: "none",
                },
              }}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image="/logos/7Daylogogreenback.png"
                  style={{ height: "140px", textTransform: 'none' }}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    7DAY Website
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                   Access our company website
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card> */}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
