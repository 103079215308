import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
} from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function TechStackPage() {
    const navigate = useNavigate();
    const onClick = (event) => {
        console.log('register onlick function', `/${event}`);
        navigate(`/${event}`, { replace: true });
    };

    return (
        <Box>
            <Box
                p={4}
                sx={{
                    backgroundColor: '#f6f8f9',
                    flexDirection: 'column',
                    height: '250px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'left',
                    paddingLeft: '80px',
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: '28px',
                            color: '#5cb5b3',
                            fontFamily: 'Open Sans',
                            fontWeight: 400,
                            letterSpacing: '-0.5px',
                        }}
                    >
                        Tech Stack
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: '#787878',
                            fontFamily: 'Open Sans',
                        }}
                    >
                        View our applications technology stack.
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ padding: '80px' }}>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#5cb5b3',
                        fontWeight: 400,
                        fontSize: '18px',
                        fontFamily: 'Open Sans',
                        letterSpacing: '-0.5px',
                    }}
                >
                    Technology Stack
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '10px',
                        color: '#5cb5b3',
                        fontWeight: 400,
                        fontSize: '18px',
                        fontFamily: 'Open Sans',
                        letterSpacing: '-0.5px',
                    }}
                >
                    Backend
                </Typography>
                <ul>
                    <li
                        style={{
                            color: '#787878',
                            fontFamily: 'Open Sans',
                            fontSize: 14,
                        }}
                    >
                        MySQL
                    </li>
                    <li
                        style={{
                            color: '#787878',
                            fontFamily: 'Open Sans',
                            fontSize: 14,
                        }}
                    >
                        NestJS
                    </li>
                </ul>

                <Typography
                    sx={{
                        paddingTop: '10px',
                        color: '#5cb5b3',
                        fontWeight: 400,
                        fontSize: '18px',
                        fontFamily: 'Open Sans',
                        letterSpacing: '-0.5px',
                    }}
                >
                    Frontend
                </Typography>
                <ul>
                    <li
                        style={{
                            color: '#787878',
                            fontFamily: 'Open Sans',
                            fontSize: 14,
                        }}
                    >
                        ReactJS
                    </li>
                </ul>

                <Typography
                    sx={{
                        paddingTop: '10px',
                        color: '#5cb5b3',
                        fontWeight: 400,
                        fontSize: '18px',
                        fontFamily: 'Open Sans',
                        letterSpacing: '-0.5px',
                    }}
                >
                    DevOps
                </Typography>

                <ul>
                    <li
                        style={{
                            color: '#787878',
                            fontFamily: 'Open Sans',
                            fontSize: 14,
                        }}
                    >
                        AWS
                    </li>
                    <li
                        style={{
                            color: '#787878',
                            fontFamily: 'Open Sans',
                            fontSize: 14,
                        }}
                    >
                        BitBucket
                    </li>
                    <li
                        style={{
                            color: '#787878',
                            fontFamily: 'Open Sans',
                            fontSize: 14,
                        }}
                    >
                        Docker
                    </li>
                    <li
                        style={{
                            color: '#787878',
                            fontFamily: 'Open Sans',
                            fontSize: 14,
                        }}
                    >
                        Tilt
                    </li>
                </ul>
                <Typography
                    sx={{
                        paddingTop: '10px',
                        color: '#5cb5b3',
                        fontWeight: 400,
                        fontSize: '18px',
                        fontFamily: 'Open Sans',
                        letterSpacing: '-0.5px',
                    }}
                >
                    QE
                </Typography>
                <ul>
                    <li
                        style={{
                            color: '#787878',
                            fontFamily: 'Open Sans',
                            fontSize: 14,
                        }}
                    >
                        Jira
                    </li>
                </ul>
            </Box>
        </Box>
    );
}
