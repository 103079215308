import Box from "@mui/material/Box";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const drawerWidth = 300;

const useStyles = makeStyles({
  root: {
    "&.app-container": {
      width: "100%",
      height: "98.5vh",
      flex: 1,
    },
    "& .app-drawer": {
      "& .MuiDrawer-root .MuiDrawer-paper": {
        paddingTop: 20,
      },

      width: drawerWidth,
      flexShrink: 0,
    },
    "& .app-content": {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: "100%",
      flex: 1,
    },
    "& .app-main": {
      flex: 1,
      height: "100%",
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
});

const DefaultLayout = (props) => {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);

  // const handleDrawerOpen = () => {
  //     setOpen(!open);
  // };
  const navigate = useNavigate();
  const onClick = (event) => {
    if (event === "Who Are We") {
      navigate(`/Overview`, { replace: true });
    } else if (event === "Database Schema") {
      navigate(`/DatabaseSchema`, { replace: true });
    } else if (event === "Tech Stack") {
      navigate(`/TechStack`, { replace: true });
    } else if (event === "Quick Links") {
      navigate(`/QuickLinks`, { replace: true });
    } else {
      navigate(`/${event}`, { replace: true });
    }
  };

  return (
    <div className={clsx(classes.root, "app-container")}>
      <nav className={clsx(classes.root, "app-drawer")}>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "#5cb5b3",
              border: 0,
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Box sx={{ width: "100%", paddingBottom: "30px" }}>
            <img
              alt="primarylogo"
              src="/logos/7Daylogogreenback.png"
              style={{
                paddingLeft: "35px",
                paddingBottom: "-10px",
                marginBottom: "-5px",
                height: "50px",
              }}
            />
            <Typography
              sx={{
                fontSize: 15,
                color: "#fff",
                fontStyle: "italic",
                justifyContent: "right",
                paddingLeft: "80px",
                fontFamily: "Open Sans",
                letterSpacing: ".15rem",
              }}
            >
              Eco-System
            </Typography>
          </Box>

          <List sx={{ color: "white" }}>
            {[
              "Home",
              "Who Are We",
            //   "Wireframes",
            //   "Database Schema",
              "Tech Stack",
              "UX",
            ].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton onClick={() => onClick(text)}>
                  <ListItemText
                    sx={{ paddingLeft: "20px" }}
                    primary={
                      <Typography
                        sx={{
                          fontFamily: "Open Sans",
                        }}
                      >
                        {text}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider
            sx={{
              borderColor: "white",
              opacity: 0.2,
            }}
          />
          <List sx={{ color: "white" }}>
            {["Brand", "Font", "Colours", "Icons"].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton onClick={() => onClick(text)}>
                  <ListItemText
                    sx={{ paddingLeft: "20px" }}
                    primary={
                      <Typography
                        sx={{
                          fontFamily: "Open Sans",
                        }}
                      >
                        {text}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider
            sx={{
              borderColor: "white",
              opacity: 0.2,
            }}
          />
          <List sx={{ color: "white" }}>
            {["Quick Links"].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton onClick={() => onClick(text)}>
                  <ListItemText
                    sx={{ paddingLeft: "20px" }}
                    primary={
                      <Typography
                        sx={{
                          fontFamily: "Open Sans",
                        }}
                      >
                        {text}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </nav>
      <div className={clsx(classes.root, "app-content")}>
        <main className={clsx(classes.root, "app-main")}>{props.children}</main>
      </div>
    </div>
  );
};

export default DefaultLayout;
