import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React from 'react';

export default function FontPage() {
    return (
        <Box>
            <Box
                p={4}
                sx={{
                    backgroundColor: '#f6f8f9',
                    flexDirection: 'column',
                    height: '250px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'left',
                    paddingLeft: '80px',
                }}
            >
                <Box>
                    <Typography
                        variant='h6'
                        noWrap
                        component='a'
                        sx={{
                            fontSize: 28,
                            color: '#5cb5b3',
                            fontFamily: 'Open Sans',
                            fontWeight: 400,
                            letterSpacing: '-0.5px',
                        }}
                    >
                        Font
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: '#787878',
                            fontFamily: 'Open Sans',
                        }}
                    >
                        View our brands typography.
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ padding: '80px' }}>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#5cb5b3',
                        fontWeight: 400,
                        fontSize: '18px',
                        fontFamily: 'Open Sans',
                        letterSpacing: '-0.5px',
                    }}
                >
                    Typography
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#787878',
                        fontFamily: 'Open Sans',
                        fontSize: 14,
                    }}
                >
                    {' '}
                    Typography and the consistent use of typefaces is a key
                    element to creating a cohesive look across all
                    communications.
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#787878',
                        fontFamily: 'Open Sans',
                        fontSize: 14,
                    }}
                >
                    To ensure consistency when setting type, formats have been
                    set for the majority of styles. These are a guide only.
                    Always exercise good judgment.
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#787878',
                        fontFamily: 'Open Sans',
                        fontSize: 14,
                    }}
                >
                    Open Sans typeface for all headlines, subtitles, body, and
                    captions, creating a cohesive typography experience.{' '}
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#787878',
                        fontFamily: 'Open Sans',
                        fontSize: 14,
                    }}
                >
                    Hierarchy is communicated through differences in font weight
                    (Regular, Semi-bold), size, letter spacing, and case.
                </Typography>
                <Box sx={{ paddingTop: '50px' }}>
                    <TableContainer>
                        <Table sx={{ width: 1000 }} aria-label='simple table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontWeight: 600,
                                            fontSize: 17,
                                            color: '#787878',
                                        }}
                                    >
                                        Scale
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontWeight: 600,
                                            fontSize: 17,
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Weight
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontWeight: 600,
                                            fontSize: 17,
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Size
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontWeight: 600,
                                            fontSize: 17,
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Case
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontWeight: 600,
                                            fontSize: 17,
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Letter Spacing
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '28px',
                                            letterSpacing: '-0.5px',
                                            fontWeight: 400,
                                            color: '#787878',
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        Display
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Regular
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        28px
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Sentence
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        -0.5px
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '22px',
                                            letterSpacing: '-0.5px',
                                            fontWeight: 400,
                                            color: '#787878',
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        H1
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Regular
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        22px
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Sentence
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        -0.5px
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '20px',
                                            letterSpacing: '-0.5px',
                                            fontWeight: 400,
                                            color: '#787878',
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        H2
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Regular
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        20px
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Sentence
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        -0.5px
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '18px',
                                            letterSpacing: '-0.5px',
                                            fontWeight: 400,
                                            color: '#787878',
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        H3
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Regular
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        18px
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Sentence
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        -0.5px
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '17px',
                                            letterSpacing: '0px',
                                            fontWeight: 600,
                                            color: '#787878',
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        H4
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Semi-bold
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        17px
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Sentence
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        0px
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '13px',
                                            letterSpacing: '0px',
                                            fontWeight: 600,
                                            color: '#787878',
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        H5
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Semi-bold
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        13px
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Sentence
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        0px
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '12px',
                                            letterSpacing: '0px',
                                            fontWeight: 600,
                                            color: '#787878',
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        H6
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Semi-bold
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        12px
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        Sentence
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        0px
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            letterSpacing: '0px',
                                            fontWeight: 400,
                                            color: '#787878',
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        Body
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Regular
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        14px
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Sentence
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        0px
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '13px',
                                            letterSpacing: '0.16px',
                                            fontWeight: 600,
                                            textTransform: 'uppercase',
                                            color: '#787878',
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        Button
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Semi-bold
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        13px
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        UPPERCASE
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        0.16px
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '12px',
                                            letterSpacing: '0px',
                                            fontWeight: 400,
                                            color: '#787878',
                                        }}
                                        component='th'
                                        scope='row'
                                    >
                                        Body
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Regular
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        12px
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        Sentence
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                        align='center'
                                    >
                                        0px
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    );
}
