import { hot } from 'react-hot-loader/root';
import CustomRouter from 'Shared/custom-router';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';

function App() {
    return (
        <BrowserRouter>
            <CustomRouter />
        </BrowserRouter>
    );
}

export default hot(App);
