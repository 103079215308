import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
} from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function OverviewPage() {
    const navigate = useNavigate();
    const onClick = (event) => {
        console.log('register onlick function', `/${event}`);
        navigate(`/${event}`, { replace: true });
    };

    return (
        <>
        <Box>
            <Box
                p={4}
                sx={{
                    backgroundColor: '#f6f8f9',
                    flexDirection: 'column',
                    height: '250px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'left',
                    paddingLeft: '80px',
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: '28px',
                            color: '#5cb5b3',
                            fontFamily: 'Open Sans',
                            fontWeight: 400,
                            letterSpacing: '-0.5px',
                        }}
                    >
                        Who Are We
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: '#787878',
                            fontFamily: 'Open Sans',
                        }}
                    >
                        7DAY's Eco-system has been created to help you achieve
                        more consistent user experiences across 7DAY products
                        and services. Explore our libraries and find all of the
                        resources you need to get started.
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ padding: '80px' }}>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#5cb5b3',
                        fontWeight: 400,
                        fontSize: '18px',
                        fontFamily: 'Open Sans',
                        letterSpacing: '-0.5px',
                    }}
                >
                    Who Are We
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#787878',
                        fontFamily: 'Open Sans',
                        fontSize: 14,
                    }}
                >
                    {' '}
                    Gone are the days where jobs are 9-5 and based in high-density environments. Our online marketplace connects people who want to rent out a property, boat, vehicle or experience to businesses who are looking for the ultimate work perk for their employees.
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#787878',
                        fontFamily: 'Open Sans',
                        fontSize: 14,
                    }}
                >
                 As the world continues to change, the 7DAY app will help businesses meet modern world expectations when it comes to work-life balance for their employees.
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#787878',
                        fontFamily: 'Open Sans',
                        fontSize: 14,
                    }}
                >
                    We aim to equally prioritise the demands of work AND the
                    demands of personal life by changing the 9-5 structure by
                    providing these culture captivating opportunities and
                    flexible locations that in return will boost team moral and
                    help businesses stand out from the crowd.
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#5cb5b3',
                        fontWeight: 200,
                        fontSize: '18px',
                        fontFamily: 'Open Sans',
                        letterSpacing: '-0.5px',
                    }}
                >
                    Our Mission
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#787878',
                        fontFamily: 'Open Sans',
                        fontSize: 14,
                    }}
                >
                    We Are Challenging the Business World By Changing the way
                    Work, works!
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#5cb5b3',
                        fontWeight: 200,
                        fontSize: '18px',
                        fontFamily: 'Open Sans',
                        letterSpacing: '-0.5px',
                    }}
                >
                    Resources
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#787878',
                        fontFamily: 'Open Sans',
                        fontSize: 14,
                    }}
                >
                    Explore our libraries and download all of the resources you
                    need to get started.
                </Typography>
                <Box
                    sx={{
                        paddingTop: '20px',
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <Card
                        onClick={() => onClick('Brand')}
                        sx={{ maxWidth: 345, margin: '20px 20px 0px 0px' }}
                    >
                        <CardActionArea>
                            <CardMedia
                                component='img'
                                height='140'
                                image='/logos/7Daylogogreenback.png'
                                style={{ height: '140px' }}
                                alt='green iguana'
                            />
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant='h5'
                                    component='div'
                                >
                                    Logo
                                </Typography>
                                <Typography
                                    variant='body2'
                                    color='text.secondary'
                                >
                                    7DAY Logos build trust and sets the tone and
                                    style of our brand.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    <Card
                        onClick={() => onClick('Font')}
                        sx={{ maxWidth: 345, margin: '20px 20px 0px 0px' }}
                    >
                        <CardActionArea>
                            <CardMedia
                                component='img'
                                height='140'
                                image='/Typography_Anatomy.jpg'
                                alt='green iguana'
                            />
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant='h5'
                                    component='div'
                                >
                                    Font
                                </Typography>
                                <Typography
                                    variant='body2'
                                    color='text.secondary'
                                >
                                    Typography forms the design and style of our
                                    brand to promote legibility, help
                                    communicate the messaging and tone of our
                                    service.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    <Card
                        onClick={() => onClick('Colours')}
                        sx={{ maxWidth: 345, margin: '20px 20px 0px 0px' }}
                    >
                        <CardActionArea>
                            <CardMedia
                                component='img'
                                height='140'
                                image='/logos/IconGreyBack.png'
                                alt='green iguana'
                            />
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant='h5'
                                    component='div'
                                >
                                    Colours
                                </Typography>
                                <Typography
                                    variant='body2'
                                    color='text.secondary'
                                >
                                    We use a specific colour palette to express
                                    vibrancy and provide a consistent look and
                                    feel.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    <Card
                        onClick={() => onClick('Icons')}
                        sx={{ maxWidth: 345, margin: '20px 20px 0px 0px' }}
                    >
                        <CardActionArea>
                            <CardMedia
                                component='img'
                                height='140'
                                image='/icon.png'
                                alt='green iguana'
                            />
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant='h5'
                                    component='div'
                                >
                                    Icons
                                </Typography>
                                <Typography
                                    variant='body2'
                                    color='text.secondary'
                                >
                                    &DAY icons adopt a system to ensure a
                                    cleaner, more consistent, and more unified
                                    look.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Box>
            </Box>
        </Box></>
    );
}
