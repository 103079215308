import BrandPage from 'pages/Brand';
import React from 'react';
import { Route, Router, Routes } from 'react-router-dom';
import history from 'utils/history';
import FontPage from 'pages/Font';
import DefaultLayout from 'Layout/DefaultLayout';
import ColoursPage from 'pages/Colours';
import IconsPage from 'pages/Icons';
import OverviewPage from 'pages/Overview';
import WireframesPage from 'pages/Wireframes';
import DatabaseSchemaPage from 'pages/DatabaseSchema';
import TechStackPage from 'pages/TechStack';
import UserExperiencePage from 'pages/UserExperience';
import QuickLinksPage from 'pages/QuickLinks';
import HomePage from '../pages/Home';

export default function CustomRouter() {
    return (
     
            <DefaultLayout>
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/Home' element={<HomePage />} />
                    <Route path='/Overview' element={<OverviewPage />} />
                    <Route path='/Wireframes' element={<WireframesPage />} />
                    <Route
                        path='/DatabaseSchema'
                        element={<DatabaseSchemaPage />}
                    />{' '}
                    <Route path='/TechStack' element={<TechStackPage />} />
                    <Route path='/Ux' element={<UserExperiencePage />} />
                    <Route path='/Brand' element={<BrandPage />} />
                    <Route path='/Font' element={<FontPage />} />
                    <Route path='/Colours' element={<ColoursPage />} />
                    <Route path='/Icons' element={<IconsPage />} />
                    <Route path='/QuickLinks' element={<QuickLinksPage />} />
                </Routes>
            </DefaultLayout>
 
    );
}
