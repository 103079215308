import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
} from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function WireframesPage() {
    const navigate = useNavigate();
    const onClick = (event) => {
        console.log('register onlick function', `/${event}`);
        navigate(`/${event}`, { replace: true });
    };

    return (
        <Box>
            <Box
                p={4}
                sx={{
                    backgroundColor: '#f6f8f9',
                    flexDirection: 'column',
                    height: '250px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'left',
                    paddingLeft: '80px',
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: '28px',
                            color: '#5cb5b3',
                            fontFamily: 'Open Sans',
                            fontWeight: 400,
                            letterSpacing: '-0.5px',
                        }}
                    >
                        Wireframes
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: '#787878',
                            fontFamily: 'Open Sans',
                        }}
                    >
                        View our 7DAY Web and App Wireframes.
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ padding: '80px' }}>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#5cb5b3',
                        fontWeight: 400,
                        fontSize: '18px',
                        fontFamily: 'Open Sans',
                        letterSpacing: '-0.5px',
                    }}
                >
                    Web Wireframes
                </Typography>

                <iframe
                    width='1000'
                    height='750'
                    src='https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FM5D8xcGVELEBtBO5Pa59Ef%2FWireframing-in-Figma%3Fnode-id%3D0%253A1%26t%3DNtBXQcaTXsOmTqQQ-1'
                    allowFullScreen
                ></iframe>
            </Box>
        </Box>
    );
}
