import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Divider,
    Modal,
    Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 4,
    p: 4,
    overflow: 'scroll',
};

export default function DatabaseSchemaPage() {
    const navigate = useNavigate();
    const onClick = (event) => {
        navigate(`/${event}`, { replace: true });
    };

    return (
        <Box>
            <Box
                p={4}
                sx={{
                    backgroundColor: '#f6f8f9',
                    flexDirection: 'column',
                    height: '250px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'left',
                    paddingLeft: '80px',
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: '28px',
                            color: '#5cb5b3',
                            fontFamily: 'Open Sans',
                            fontWeight: 400,
                            letterSpacing: '-0.5px',
                        }}
                    >
                        Database Schema
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: '#787878',
                            fontFamily: 'Open Sans',
                        }}
                    >
                        View our database schema (ERD) that represents the logic
                        and configuration of our relational database.
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ padding: '80px' }}>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#5cb5b3',
                        fontWeight: 400,
                        fontSize: '18px',
                        fontFamily: 'Open Sans',
                        letterSpacing: '-0.5px',
                    }}
                >
                    ERD
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#787878',
                        fontFamily: 'Open Sans',
                        fontSize: 14,
                    }}
                >
                    A user is a 7DAY Client that has many modules and companies.
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#787878',
                        fontFamily: 'Open Sans',
                        fontSize: 14,
                    }}
                >
                    Companies have many staff and bookings.
                </Typography>

                <img
                    alt='client'
                    src='/schemas/ERD.png'
                    style={{ padding: '50px 0px 50px 0px', maxWidth: '1050px' }}
                />
            </Box>
        </Box>
    );
}
