import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export default function BrandPage() {
    return (
        <Box>
            <Box
                p={4}
                sx={{
                    backgroundColor: '#f6f8f9',
                    flexDirection: 'column',
                    height: '250px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'left',
                    paddingLeft: '80px',
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: '28px',
                            color: '#5cb5b3',
                            fontFamily: 'Open Sans',
                            fontWeight: 400,
                            letterSpacing: '-0.5px',
                        }}
                    >
                        Brand
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: '#787878',
                            fontFamily: 'Open Sans',
                        }}
                    >
                        Download our brand logos.
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ padding: '80px' }}>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#5cb5b3',
                        fontWeight: 400,
                        fontSize: '18px',
                        fontFamily: 'Open Sans',
                        letterSpacing: '-0.5px',
                    }}
                >
                    Logos
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#787878',
                        fontFamily: 'Open Sans',
                        fontSize: 14,
                    }}
                >
                    {' '}
                    All references to 7DAY must always be used according to the
                    specifications in this document. Any use that falls outside
                    these specifications is strictly prohibited.
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#787878',
                        fontFamily: 'Open Sans',
                        fontSize: 14,
                    }}
                >
                    7DAY's only visual representation is the 7DAY logo. The 7DAY
                    logo has been developed to express the personality of the
                    brand and identify the company within the industry. The logo
                    or icon should never be taken apart or re-combined in any
                    way to create new artwork.
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#5cb5b3',
                        fontWeight: 200,
                        fontSize: '18px',
                        fontFamily: 'Open Sans',
                        letterSpacing: '-0.5px',
                    }}
                >
                    Primary Logo
                </Typography>
                <Link
                    to={
                        'https://www.dropbox.com/s/5dc2cnjxg8vf7x0/7Daylogo.png?dl=0'
                    }
                    target='blank'
                >
                    <img
                        alt='primarylogo'
                        src='/logos/7Daylogo.png'
                        style={{ padding: '50px 0px 50px 0px' }}
                    />
                </Link>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#5cb5b3',
                        fontWeight: 400,
                        fontSize: '18px',
                        fontFamily: 'Open Sans',
                        letterSpacing: '-0.5px',
                    }}
                >
                    Secondary Logos
                </Typography>
                <Link
                    to={
                        'https://www.dropbox.com/s/162ok8zmgmovxob/7Daylogogreenback.png?dl=0'
                    }
                    target='blank'
                >
                    <img
                        alt='buggy'
                        src='/logos/7Daylogogreenback.png'
                        style={{ padding: '50px 0px 50px 0px' }}
                    />
                </Link>
                <Link
                    to={
                        'https://www.dropbox.com/s/ee97lcxecrstfby/7Daylogogreyback.png?dl=0'
                    }
                    target='blank'
                >
                    <img
                        alt='buggy'
                        src='/logos/7Daylogogreyback.png'
                        style={{ padding: '50px 0px 50px 50px' }}
                    />
                </Link>

                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#5cb5b3',
                        fontWeight: 400,
                        fontSize: '18px',
                        fontFamily: 'Open Sans',
                        letterSpacing: '-0.5px',
                    }}
                >
                    Icons
                </Typography>
                <Link
                    to={
                        'https://www.dropbox.com/s/1dzgir8qf9l03p0/iconWhiteBack.png?dl=0'
                    }
                    target='blank'
                >
                    <img
                        alt='buggy'
                        src='/logos/IconWhiteBack.png'
                        style={{ padding: '50px 0px 50px 0px' }}
                    />
                </Link>
                <Link
                    to={
                        'https://www.dropbox.com/s/43og9z2u6uy86yz/IconGreenBack.png?dl=0'
                    }
                    target='blank'
                >
                    <img
                        alt='buggy'
                        src='/logos/IconGreenBack.png'
                        style={{ padding: '50px 0px 50px 50px' }}
                    />
                </Link>
                <Link
                    to={
                        'https://www.dropbox.com/s/pc2d5yeig277fhz/IconGreyBack.png?dl=0'
                    }
                    target='blank'
                >
                    <img
                        alt='buggy'
                        src='/logos/IconGreyBack.png'
                        style={{ padding: '50px 0px 50px 50px' }}
                    />
                </Link>

                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#5cb5b3',
                        fontWeight: 400,
                        fontSize: '18px',
                        fontFamily: 'Open Sans',
                        letterSpacing: '-0.5px',
                    }}
                >
                    Protective Area
                </Typography>
                <Typography
                    sx={{
                        paddingTop: '20px',
                        color: '#787878',
                        fontFamily: 'Open Sans',
                        fontSize: '14px',
                    }}
                >
                    {' '}
                    For best results, the minimum size height for the web is
                    25px.
                </Typography>
            </Box>
        </Box>
    );
}
