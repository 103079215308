import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React from 'react';

export default function ColoursPage() {
    return (
        <Box>
            <Box
                p={4}
                sx={{
                    backgroundColor: '#f6f8f9',
                    flexDirection: 'column',
                    height: '250px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'left',
                    paddingLeft: '80px',
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: '28px',
                            color: '#5cb5b3',
                            fontFamily: 'Open Sans',
                            fontWeight: 400,
                            letterSpacing: '-0.5px',
                        }}
                    >
                        Colours
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: 15,
                            color: '#787878',
                            fontFamily: 'Open Sans',
                        }}
                    >
                        We use a specific colour palette to express vibrancy and
                        provide a consistent look and feel.
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ padding: '80px' }}>
                <Typography
                    sx={{
                        paddingTop: '22px',
                        color: '#5cb5b3',
                        fontWeight: 400,
                        fontSize: '22px',
                        fontFamily: 'Open Sans',
                        letterSpacing: '-0.5px',
                    }}
                >
                    Colour Palette
                </Typography>

                <Box sx={{ paddingTop: '20px' }}>
                    <Typography
                        sx={{
                            paddingTop: '17px',
                            color: '#5cb5b3',
                            fontWeight: 400,
                            fontSize: '18px',
                            fontFamily: 'Open Sans',
                            letterSpacing: '-0.5px',
                        }}
                    >
                        Primary
                    </Typography>
                    <Typography
                        sx={{
                            paddingTop: '20px',
                            color: '#787878',
                            fontWeight: 200,
                            fontSize: '14px',
                            fontFamily: 'Open Sans',
                        }}
                    >
                        The primary 7DAY colours are aqua and white.
                    </Typography>
                    <Typography
                        sx={{
                            paddingTop: '20px',
                            color: '#787878',
                            fontWeight: 400,
                            fontSize: '14px',
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {' '}
                        7DAY Aqua brings out the sentiments of revitalization,
                        youth and dreams. It embodies the strength and energy of
                        our company. It should be used wherever you want to
                        convey a graphic vibrancy.
                    </Typography>
                    <Typography
                        sx={{
                            paddingTop: '20px',
                            color: '#787878',
                            fontWeight: 200,
                            fontSize: '14px',
                            fontFamily: 'Open Sans',
                        }}
                    >
                        7D White conveys the goodness, optimisim, equality,
                        energy and idealism. It supports the colour 7DAY Aqua.
                    </Typography>
                    <Typography
                        sx={{
                            paddingTop: '17px',
                            color: '#5cb5b3',
                            fontWeight: 400,
                            fontSize: '18px',
                            fontFamily: 'Open Sans',
                            letterSpacing: '-0.5px',
                        }}
                    >
                        Secondary
                    </Typography>
                    <Typography
                        sx={{
                            paddingTop: '20px',
                            color: '#787878',
                            fontWeight: 400,
                            fontSize: '14px',
                            fontFamily: 'Open Sans',
                        }}
                    >
                        Our secondary colours should be used sparingly
                        throughout the product, and used with primary colours to
                        visually represent the 7DAY brand.
                    </Typography>

                    <TableContainer>
                        <Table sx={{ width: 1000 }} aria-label='simple table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align='center'>HEX</TableCell>

                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        RGB
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        CMYK
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ backgroundColor: '#EEEEEE' }}>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        component='th'
                                        scope='row'
                                    >
                                        <Box
                                            sx={{
                                                backgroundColor: '#5cb5b3',
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '5px',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                color: '#787878',
                                                fontSize: '14px',
                                                fontFamily: 'Open Sans',
                                                fontWeight: 700,
                                            }}
                                        >
                                            Primary
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        #5cb5b3
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        92, 181,179
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        49, 0, 1, 29
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        component='th'
                                        scope='row'
                                    >
                                        <Box
                                            sx={{
                                                backgroundColor: '#fff',
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '5px',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                color: '#787878',
                                                fontSize: '14px',
                                                fontFamily: 'Open Sans',
                                                fontWeight: 700,
                                            }}
                                        >
                                            Primary Lighter
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        #FFFFFF
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        255, 255, 255
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        0, 0, 0, 0
                                    </TableCell>
                                </TableRow>

                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        component='th'
                                        scope='row'
                                    >
                                        <Box
                                            sx={{
                                                backgroundColor: '#787878',
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '5px',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                color: '#787878',
                                                fontSize: '14px',
                                                fontFamily: 'Open Sans',
                                                fontWeight: 700,
                                            }}
                                        >
                                            Secondary
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        #787878
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        120, 120, 120
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        0, 0, 0, 53
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        component='th'
                                        scope='row'
                                    >
                                        <Box
                                            sx={{
                                                backgroundColor: '#f6f8f9',
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '5px',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                color: '#787878',
                                                fontSize: '14px',
                                                fontFamily: 'Open Sans',
                                                fontWeight: 700,
                                            }}
                                        >
                                            Secondary Lighter
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        #f6f8f9
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        246, 248, 249
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        1, 0, 0, 2{' '}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box sx={{ paddingTop: '0px' }}>
                    <Typography
                        sx={{
                            paddingTop: '20px',
                            color: '#5cb5b3',
                            fontWeight: 400,
                            fontSize: '18px',
                            fontFamily: 'Open Sans',
                            letterSpacing: '-0.5px',
                        }}
                    >
                        Text
                    </Typography>
                    <TableContainer>
                        <Table sx={{ width: 1000 }} aria-label='simple table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        HEX
                                    </TableCell>

                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        RGB
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        CMYK
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ backgroundColor: '#EEEEEE' }}>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        component='th'
                                        scope='row'
                                    >
                                        <Box
                                            sx={{
                                                backgroundColor: '#787878',
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '5px',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                color: '#787878',
                                                fontSize: '14px',
                                                fontFamily: 'Open Sans',
                                                fontWeight: 700,
                                            }}
                                        >
                                            Text
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        #787878
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        120, 120, 120
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        0, 0, 0, 53
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        component='th'
                                        scope='row'
                                    >
                                        <Box
                                            sx={{
                                                backgroundColor: '#5cb5b3',
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '5px',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                color: '#787878',
                                                fontSize: '14px',
                                                fontFamily: 'Open Sans',
                                                fontWeight: 700,
                                            }}
                                        >
                                            Secondary
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        #5cb5b3
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        92, 181,179
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        49, 0, 1, 29
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        component='th'
                                        scope='row'
                                    >
                                        <Box
                                            sx={{
                                                backgroundColor: '#fff',
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '5px',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                color: '#787878',
                                                fontSize: '14px',
                                                fontFamily: 'Open Sans',
                                                fontWeight: 700,
                                            }}
                                        >
                                            Secondary Lighter
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        #FFFFFF
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        255, 255, 255
                                    </TableCell>
                                    <TableCell
                                        sx={{ fontFamily: 'Open Sans' }}
                                        align='center'
                                    >
                                        0, 0, 0, 0
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    );
}
